/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { FC, useCallback, useState } from 'react';
import { ICommand } from 'stores/events-store';
import { useIntl } from 'react-intl';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import { format } from 'date-fns';
import { useStore } from 'stores/store-hooks';
import { formatDate, shortDuration } from 'translations/locales';

export const CommandEvent: FC<{
  command: ICommand;
}> = ({ command }) => {
  const theme = useTheme();
  const eventStore = useStore('eventStore');
  const [commandCancelled, setCommandCancelled] = useState(false);
  const intl = useIntl();
  const date = command.creationTime ? new Date(command.creationTime) : '';
  const time = date ? format(date, 'HH:mm') : '';
  const formattedDate = date ? formatDate(intl, date) : '';
  const commandDeliveryTime = command.delivery?.time
    ? format(new Date(command.delivery.time), 'HH:mm')
    : '';
  const commandCreationTime = command.creationTime
    ? format(new Date(command.creationTime), 'HH:mm')
    : '';
  const commandStatus = command.status
    ? intl.formatMessage({ id: 'feeder.' + command.status.toLowerCase() })
    : '';
  const failureReasonText = command.failureReason
    ? intl.formatMessage({
        id: 'feeder.error_' + command.failureReason.toLowerCase(),
      })
    : '';

  const getIcon = useCallback(() => {
    const iconStyle = (color: string) => css`
      min-width: 50px;
      min-height: 50px;
      color: ${color};
    `;
    if (command.status === 'PENDING') {
      return (
        <PauseCircleFilledIcon css={iconStyle(theme.palette.warning.main)} />
      );
    }
    if (command.status === 'EXECUTING') {
      return <PlayArrowIcon css={iconStyle(theme.palette.success.main)} />;
    }
    if (command.status === 'SUCCESSFUL') {
      return <CheckCircleIcon css={iconStyle(theme.palette.success.main)} />;
    }
    if (command.status === 'FAILED') {
      return <BlockIcon css={iconStyle(theme.palette.error.main)} />;
    }
    return null;
  }, [command.status, theme]);

  const parseCommandForTranslation = useCallback(
    (commandDescription: string = '', commandText: string = '') => {
      if (commandDescription.indexOf(':') !== -1) {
        const split = commandDescription.split(' ');
        const translation = `feeder.${split[split.length - 1].toLowerCase()}`;
        return intl.formatMessage({ id: translation }) + (
          split[split.length - 1] === "force_feed" && commandText
            ? " (" + shortDuration(JSON.parse(commandText).time) + ")"
            : ""
        );
      } else {
        const split = commandDescription.split(' ');
        const translation = `feeder.${split.join('_').toLowerCase()}`;
        return intl.formatMessage({ id: translation });
      }
    },
    [intl]
  );

  return (
    <Box
      css={css`
        display: flex;
        background-color: ${theme.palette.grey[100]};
        margin-bottom: ${theme.spacing(3)}px;
      `}
    >
      <Box
        css={css`
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.12);
          min-width: 90px;
          min-height: 120px;
        `}
      >
        <Typography component="span" variant="overline">
          {formattedDate}
        </Typography>
        <Typography component="span" variant="overline">
          {time}
        </Typography>
        {getIcon()}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flex="1 1 100%"
        justifyContent="center"
      >
        <Box
          css={(theme) => css`
            border-radius: 4px;
            padding: ${theme.spacing(2)}px;
          `}
        >
          <Typography component="div" variant="body1" color="primary">
            {parseCommandForTranslation(
              command.description,
              command.c8y_Command?.text || ""
            )}
          </Typography>{' '}
          <Typography component="div" variant="body1" color="primary">
            {`${intl.formatMessage({ id: 'LastStatus' })} (${
              commandDeliveryTime || commandCreationTime
            }): ${commandStatus}`}
          </Typography>
          {command.status === 'FAILED' &&
            command.failureReason &&
            command.failureReason.length > 0 && (
              <Typography component="div" variant="body1" color="primary">
                {`${intl.formatMessage({
                  id: 'ErrorReason',
                })}: ${failureReasonText}`}
              </Typography>
            )}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flex="1 1 auto"
        pr={2}
        justifyContent="center"
        css={css`
          white-space: nowrap;
        `}
      >
        <Typography component="div" variant="body2" color="primary">
          {intl.formatMessage({ id: 'DeliveryLog' })}
        </Typography>
        {command.delivery?.log &&
          command.delivery?.log.map((status, index) => {
            if (index > 4) return null; // limit to 5 first log entries
            const statusTime = status.time
              ? format(new Date(status.time), 'HH:mm')
              : '';
            const statusText = status.status
              ? intl.formatMessage({
                  id: 'feeder.' + (status.status as string).toLowerCase(),
                })
              : '';
            return (
              <Typography
                key={index}
                component="div"
                variant="body2"
                color="primary"
              >{`${statusText} ${statusTime}`}</Typography>
            );
          })}
      </Box>
      {!commandCancelled &&
        command.status !== 'SUCCESSFUL' &&
        command.status !== 'FAILED' && (
          <Box
            display="flex"
            flexDirection="column"
            flex="1 1 auto"
            pr={2}
            justifyContent="center"
          >
            <Button
              variant="outlined"
              css={css`
                &.MuiButton-root {
                  background-color: ${theme.palette.error.main};
                  color: ${theme.palette.common.white};
                  :hover {
                    background-color: ${theme.palette.error.dark};
                  }
                }
              `}
              onClick={async () => {
                const success = eventStore.cancelCommand(command);
                if (success != null) setCommandCancelled(true);
              }}
            >
              {intl.formatMessage({ id: 'Cancel' }).toUpperCase()}
            </Button>
          </Box>
        )}
    </Box>
  );
};
