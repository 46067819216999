import {Typography} from "@material-ui/core";
import {useIntl} from "react-intl";

export const HelpCenterText = () => {
  const intl = useIntl();
  return (
    <div style={{textAlign: "center"}}>
      <Typography variant="h4">
        Help Center
      </Typography>
      <Typography variant="body2" style={{marginBottom: '7px'}}>
        {intl.formatMessage({id: "HCHours"})}
      </Typography>
      <Typography variant="body2">
        Marko Tyynelä<br/>
        {intl.formatMessage({id: "ProjectManager"})}<br/>
        +358 50 367 2425<br/>
        marko.tyynela@solvomate.fi
      </Typography>
    </div>
  );
};
