import { makeAutoObservable, runInAction } from 'mobx';
import {
  IApiResponse,
  archiveAndCopyFishBasin,
  archiveFishBasin,
  createBasinForSite,
  getFishBasin,
  getFishTypes,
  getSeasonReport,
  getSimpleActiveFishBasins,
  saveBasinOrder,
  setCalculationStatus,
  splitBasin,
  updateBasinById,
  getIotDeviceInfo,
  postBasinExtraFeedEvent,
  getFishBasinAtu,
  postBasinAutomationToggleEvent,
  postBasinForceFeedingEvent, getFishBasinRemovedFish,
} from 'services/api';
import {IBasinDimensions, IBasinSplitData, IFishType} from 'models/fishbasin';
import {
  IFishBasin,
  IBasinStartValues,
  IBasinArchiveData,
} from 'models/fishbasin';
import {IDeviceInfoResponse} from 'models/iot-devices';
import filterStore from './filter-store';
import eventStore from './events-store';

interface IFishTypeFilter {
  id: number; // id of fish type or -1 to show all
}

class BasinStore {
  constructor() {
    makeAutoObservable(this);
  }

  fishTypes: IFishType[] = [];
  basins: IFishBasin[] = []; // selected basins for inspection/editing. set by facility view and used in details view.
  basinsIncludesIot: boolean = false;
  selectedBasin: IFishBasin | null = null; // basin selection from facility-view or from basin list
  videoStreamUrl: string | undefined;
  archiveList: IBasinArchiveData[] = [];
  isLoading: boolean = false;

  fishtypeFilter: IFishTypeFilter = {
    id: -1,
  };

  /** Computed value. Automation is considered enabled if support unit id is attached */
  get hasIotAutomation() {
    return !!this.selectedBasin?.supportunit_id;
  }

  /** Get the latest extra feed event of today if exists and return the weight*/
  getTodaysTotalExtraFeed(basin?: IFishBasin) {
    const loadTotalExtraFeedAmount = async () => {
      if(!basin) return 0;
      const events = await eventStore.loadEventsByType(basin.id, 'X')
      if(events.length === 0) return 0;
      return events
        .filter(e => {
          const eventDay = new Date(e.timestamp).setHours(0, 0, 0, 0);
          const today = new Date().setHours(0, 0, 0, 0);
          return eventDay === today;
        })
        .map(e => e.values ? e.values.extrafeedweight || 0 : 0)
        .reduce((prevVal, currentVal) => prevVal + currentVal, 0);
    }
    return loadTotalExtraFeedAmount();
  }

  addExtraFeedForToday = async (weightInGrams: number) => {
    if (this.selectedBasin && this.selectedBasin?.id) {
      try {
        const response = await postBasinExtraFeedEvent(this.selectedBasin.id, weightInGrams);
        runInAction(() => {
          const extraFeedWeight = this.selectedBasin?.extra_feed_weight || 0;
          this.selectedBasin = {...this.selectedBasin, extra_feed_weight: extraFeedWeight + weightInGrams} as IFishBasin;
        })
        return {
          status: response?.status,
          data: response.data,
        };
      } catch (error: any) {
        return {
          errors: error?.response?.data,
          status: error?.response?.status,
          data: null,
        };
      }
    }
    return {
      status: 400,
      data: null,
    };
  };

  getAtu(basin?: IFishBasin | null) {
    const loadAtu = async () => {
      if(!basin) return 0;
      try {
        const response = await getFishBasinAtu(basin.id+'');
        return response.data.accumulated_thermal_unit;
      } catch(error) {
        console.error(error);
        return 0;
      }
    }
    return loadAtu();
  }

  getRemovedFish(basin?: IFishBasin | null) {
    const loadRemovedFish = async () => {
      if(!basin) return [0, 0];
      try {
        const response = await getFishBasinRemovedFish(basin.id+'');
        return [response.data.total_averageweight, response.data.total_fishamountcorrection];
      } catch(error) {
        console.error(error);
        return [0, 0];
      }
    }
    return loadRemovedFish();
  }

  reset = () => {
    this.basins = [];
    this.basinsIncludesIot = false;
    this.selectedBasin = null;
    this.archiveList = [];
    this.videoStreamUrl = undefined;
  };

  updateBasin = async (
    basinId: number,
    basin: IFishBasin
  ): Promise<IApiResponse<IFishBasin>> => {
    try {
      const response = await updateBasinById(basinId, basin);
      return {
        status: response?.status,
        data: response.data,
      };
    } catch (error: any) {
      return {
        errors: error?.response?.data,
        status: error?.response?.status,
        data: null,
      };
    }
  };

  createBasin = async (
    siteId: number,
    basin: IFishBasin
  ): Promise<IApiResponse<IFishBasin>> => {
    try {
      const response = await createBasinForSite(siteId, basin);
      return {
        status: response?.status,
        data: response.data,
      };
    } catch (error: any) {
      return {
        errors: error?.response?.data,
        status: error?.response?.status,
        data: null,
      };
    }
  };

  async loadFishTypes() {
    const response = await getFishTypes();
    runInAction(() => {
      this.fishTypes = response.data;
    });
  }

  setSelectedBasin = async (basin: IFishBasin | null) => {
    if (basin === null) {
      runInAction(() => {
        this.selectedBasin = null;
      });
    } else {
      const extraFeedWeight = await this.getTodaysTotalExtraFeed(basin || undefined);
      runInAction(() => {
        this.selectedBasin = {...basin, extra_feed_weight: extraFeedWeight} as IFishBasin;
      });
    }
  };

  loadBasin = async (basinId: string): Promise<IApiResponse<IFishBasin>> => {
    try {
      const response = await getFishBasin(basinId);
      const extraFeedWeight = await this.getTodaysTotalExtraFeed(response.data);
      runInAction(() => {
        this.selectedBasin = {
          ...response.data,
          extra_feed_weight: extraFeedWeight,
          // activeAlarms: true,
          // activeAlarmsCount: 4,
        };
      });
      return {
        status: response?.status,
        data: response.data,
      };
    } catch (error: any) {
      return {
        errors: error?.response?.data,
        status: error?.response?.status,
        data: null,
      };
    }
  };

  private loadBasinDeviceInfo = async (basin: IFishBasin): Promise<IApiResponse<IDeviceInfoResponse>> => {
    let data: IDeviceInfoResponse | null = null;
    if (basin.supportunit_id) {
      try {
        const response = await getIotDeviceInfo(basin.supportunit_id);
        data = response.data;
        if (data.custom_props && data.custom_props.status) {
          // parse status result coming from integration service / iot device
          const { status } = data.custom_props;
          data.custom_props.parsedStatus = JSON.parse(status);
        }
        return {
          status: response.status,
          data,
        };
      } catch (error: any) {
        return {
          status: 400,
          data,
          errors: error,
        };
      }
    }

    return {
      status: 400,
      data,
    };
  };

  loadSimpleActiveBasins = async (loadIot: boolean = false) => {
    runInAction(() => {
      this.isLoading = true;
    });
    const { data } = await getSimpleActiveFishBasins();
    if (loadIot) {
      const deviceInfoPromises = data.map(async (basin, index) => {
        try {
          const iot = await this.loadBasinDeviceInfo(basin);
          basin.deviceInfo = iot.data;
          basin.temperature = basin.site.last_temperature?.temperature;
          basin.oxygenvalue = basin.site.last_oxygen_value?.oxygenvalue;
          basin.biomass_m3 = this.getBasinBiomassForEachCubicMeter(basin.dimensions, basin.biomass);
          this.basinsIncludesIot = true;
        } catch (e) {
          console.error('IoT device info parsing error for basin', basin.id, e);
        }
      });
      await Promise.all(deviceInfoPromises);
    }
    runInAction(() => {
      this.basins = data.filter((b) => !b.deleted);
    });
    runInAction(() => {
      this.isLoading = false;
    });
  };

  getBasinBiomassForEachCubicMeter = (dimensions?: IBasinDimensions, biomass?: number): number => {
    // width and length do not exist, using diameter and depth only should be fine?
    if (dimensions?.diameter && dimensions?.depth && biomass) {

      const radius = dimensions.diameter / 2;
      const height = dimensions.depth;
      const volume = height * Math.PI * radius * radius / 100;
      const bMass = biomass / volume;

      return Number.parseFloat(Number(bMass).toFixed(2));
    }
    return 0;
  }

  toggleCalculation = async (
    basinId: number
  ): Promise<IApiResponse<IFishBasin>> => {
    try {
      const response = await setCalculationStatus(
        basinId,
        !this.selectedBasin?.feedingenabled
      );
      const extraFeedWeight = await this.getTodaysTotalExtraFeed(response.data);
      runInAction(() => {
        this.selectedBasin = {...response.data, extra_feed_weight: extraFeedWeight};
      });
      return {
        status: response?.status,
        data: response.data,
      };
    } catch (error: any) {
      return {
        errors: error?.response?.data,
        status: error?.response?.status,
        data: null,
      };
    }
  };

  splitBasin = async (basinId: number, data: IBasinSplitData[]) => {
    try {
      const { data: newBasins } = await splitBasin(basinId, data);
      return newBasins;
    } catch (error) {
      return null; // split requested failed, indicate error to user
    }
  };

  downloadSeasonReport = async (basinId: number) => {
    const { data } = await getSeasonReport(basinId);
    return data;
  };

  archiveBasin = async (basinId: number): Promise<IApiResponse<IFishBasin>> => {
    try {
      const response = await archiveFishBasin(basinId);
      return {
        status: response?.status,
        data: response.data,
      };
    } catch (error: any) {
      return {
        errors: error?.response?.data,
        status: error?.response?.status,
        data: null,
      };
    }
  };

  archiveAndCopy = async (
    basinId: number,
    startValues: IBasinStartValues
  ): Promise<IApiResponse<IFishBasin>> => {
    try {
      const response = await archiveAndCopyFishBasin(basinId, startValues);
      return {
        status: response?.status,
        data: response.data,
      };
    } catch (error: any) {
      return {
        errors: error?.response?.data,
        status: error?.response?.status,
        data: null,
      };
    }
  };

  updateArchiveList(archiveData: IBasinArchiveData, action: 'add' | 'remove') {
    if (action === 'remove') {
      runInAction(() => {
        this.archiveList = this.archiveList.filter(
          (entry) => entry.id !== archiveData.id
        );
      });
    }
    if (action === 'add') {
      runInAction(() => {
        // this.basins = this.basins.filter((b) => b.id !== archiveData.id);
        this.archiveList.push(archiveData);
      });
    }
  }

  clearArchiveList() {
    runInAction(() => {
      this.archiveList = [];
    });
  }

  async archiveBasins(): Promise<number[]> {
    const failedIds: number[] = [];
    const promises = this.archiveList.map(async (entry) => {
      try {
        if (entry.startvalues) {
          await archiveAndCopyFishBasin(entry.id, entry.startvalues);
        } else {
          await archiveFishBasin(entry.id);
        }
        runInAction(() => {
          // clear id from delete list after successfully removal
          this.archiveList = this.archiveList.filter((e) => e.id !== entry.id);
        });
      } catch (error) {
        failedIds.push(entry.id);
      }
    });
    await Promise.all(promises);
    return failedIds;
  }

  setFishTypeFilter(id: number) {
    runInAction(() => {
      this.fishtypeFilter = { id };
    });
  }

  // Synchronous, but returns a promise
  saveOrder = (basinIds: number[]) => {
    runInAction(() => {
      this.basins.forEach((basin) => {
        basin.custom_ordering_index = basinIds.indexOf(basin.id) + 1;
      });
    });
    return saveBasinOrder(basinIds);
  };

  get filteredBasins() {
    let basins = this.basins;
    if (filterStore.dataFilter.type === 'company') {
      basins = basins.filter(
        (basin) => basin.site.facility.company?.id === filterStore.dataFilter.id
      );
    }
    if (filterStore.dataFilter.type === 'facility') {
      basins = basins.filter(
        (basin) => basin.site.facility.id === filterStore.dataFilter.id
      );
    }
    if (filterStore.dataFilter.type === 'site') {
      basins = basins.filter(
        (basin) => basin.site.id === filterStore.dataFilter.id
      );
    }
    if (this.fishtypeFilter.id !== -1) {
      basins = basins.filter(
        (basin) => basin.fishtype?.id === this.fishtypeFilter.id
      );
    }
    return basins;
  }

  get totalBiomass() {
    return this.filteredBasins.reduce(
      (total, basin) =>
        total +
        (basin?.currentbiomasses !== undefined
          ? basin?.currentbiomasses[0]
          : 0),
      0
    );
  }

  get totalFeedAmount() {
    return this.filteredBasins.reduce(
      (total, basin) => total + (basin?.currentfeedinfo?.feed_amount || 0),
      0
    );
  }

  addAutomationToggleEvent = async (basin: IFishBasin | undefined, value: boolean) => {
    if (basin?.id) {
      try {
        const response = await postBasinAutomationToggleEvent(basin?.id, value);
        return {
          status: response?.status,
          data: response.data,
        };
      } catch (error: any) {
        return {
          errors: error?.response?.data,
          status: error?.response?.status,
          data: null,
        };
      }
    }
    return {
      status: 400,
      data: null,
    };
  };

  addForceFeedingEvent = async (basin: IFishBasin | null | undefined, duration: number) => {
    if (basin?.id) {
      try {
        const response = await postBasinForceFeedingEvent(basin?.id, duration);
        return {
          status: response?.status,
          data: response.data,
        };
      } catch (error: any) {
        return {
          errors: error?.response?.data,
          status: error?.response?.status,
          data: null,
        };
      }
    }
    return {
      status: 400,
      data: null,
    };
  };

}

export default new BasinStore();
