import {observer} from "mobx-react-lite";
import {useIntl} from "react-intl";
import {useStore} from "../../../../stores/store-hooks";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Slider,
  TextField, Tooltip,
  Typography
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {HarvestingAction} from "../../../../models/harvesting";
import React, {Fragment, useEffect, useState} from "react";
import {Modal, ModalActions, ModalContent} from "../../../modal-dialog";
import {NumericInput} from "../../../input-fields";
import {DatePicker} from "@material-ui/pickers";
import {formatDate} from "../../../../translations/locales";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {CleaningFields} from "./cleaning-fields";

const FastAndCleanDialog = observer(() => {
  const intl = useIntl();
  const { selectedBasin } = useStore('basinStore');
  const {
    amountToFastOrHarvest,
    fastOrHarvestAll,
    estimateLiveWeight,
    setActionFastAndClean,
    selectHarvestingDateIfPossible,
    setAmountToFastOrHarvest,
    calculateHarvestingValues,
    fastToHarvest,
    clearAll,
    details, setDetails, performAction, harvestingDate, setHarvestingDate,
    fishbasinStatuses, fetchFishbasinStatuses
  } = useStore('harvestingStore');
  const [isRetroactiveHarvest, setRetroactiveHarvest] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDateLoading, setDateLoading] = useState(false);

  useEffect(() => {
    const handleEnterPress = (event: any) => {
      if(event.key === 'Enter') calculateHarvestingValues();
    }

    window.addEventListener('keydown', handleEnterPress);

    return () => {
      window.removeEventListener('keydown', handleEnterPress);
    };
  }, [calculateHarvestingValues]);

  const onDatePicked = async (date: MaterialUiPickersDate) => {
    const today = new Date();
    if(date?.toLocaleDateString() === today.toLocaleDateString()) {
      setRetroactiveHarvest(false);
      setHarvestingDate(today);
      setActionFastAndClean();
      return;
    }

    setDateLoading(true);
    try {
      await selectHarvestingDateIfPossible(date);
      setError(null);
    } catch(e) {
      // @ts-ignore
      setError(e.message);
    }
    setDateLoading(false);
  }

  return (
    <Modal handleClose={clearAll}>
      <ModalContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="span" variant="subtitle2">
              {intl.formatMessage({ id: "FastAndHarvest" })}
            </Typography>
          </Grid>
          <Grid container alignItems="center" spacing={4} item xs={12}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!isRetroactiveHarvest}
                    onChange={() => {
                      if(isRetroactiveHarvest) {
                        setRetroactiveHarvest(false);
                        setActionFastAndClean();
                        setHarvestingDate(new Date());
                        setError(null);
                      }
                      else {
                        fetchFishbasinStatuses();
                        setRetroactiveHarvest(true);
                      }
                    }}
                  />
                }
                label={intl.formatMessage({ id: "HarvestToCurrentTime" })}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                autoOk
                disableToolbar
                fullWidth
                disabled={!isRetroactiveHarvest}
                label={intl.formatMessage({ id: "DateOfHarvest" })}
                labelFunc={(date) => formatDate(intl, date || new Date())}
                onChange={(date) => onDatePicked(date)}
                shouldDisableDate={date => {
                  const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                  return date
                    ? !fishbasinStatuses.find(status => status.eventdate === new Date(date.getTime() - tzoffset).toISOString().split('T')[0])
                    : true
                }}
                value={harvestingDate}
                variant="inline"
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => {}}>
                      <CalendarTodayIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
          {isRetroactiveHarvest && !isDateLoading && error ?
            <Fragment>
              <Typography variant='h4'>
                {intl.formatMessage({ id: error })}
              </Typography>
            </Fragment> :
            <Fragment>
              <Grid container alignItems="center" spacing={4} item xs={12}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isRetroactiveHarvest}
                        checked={fastOrHarvestAll}
                        onChange={() => {
                          if (fastOrHarvestAll) {
                            // This also sets fastOrHarvestAll to false
                            setAmountToFastOrHarvest(amountToFastOrHarvest);
                          } else {
                            setActionFastAndClean(); // Sets fastOrHarvestAll to true
                          }
                        }}
                      />
                    }
                    label={intl.formatMessage({ id: "All" })}
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumericInput
                    disabled={fastOrHarvestAll || isRetroactiveHarvest}
                    label={intl.formatMessage({ id: "Amount" })}
                    max={isRetroactiveHarvest ? fastToHarvest?.amount : selectedBasin?.currentamount}
                    onChange={setAmountToFastOrHarvest}
                    value={amountToFastOrHarvest}
                  />
                </Grid>
              </Grid>
              {fastOrHarvestAll || isRetroactiveHarvest
                ? null
                : <Grid item xs={12}>
                  <Slider
                    aria-label={intl.formatMessage({ id: "FastingSlider" })}
                    getAriaValueText={(v) =>
                      v + " " + intl.formatMessage({ id: "Pieces" })
                    }
                    min={0}
                    max={selectedBasin?.currentamount || 1}
                    onChange={(e, v) => setAmountToFastOrHarvest(v as number)}
                    step={1}
                    value={amountToFastOrHarvest}
                  />
                </Grid>}
              <Grid item xs={12}>
                <Typography component="p" variant="body1">
                  {intl.formatMessage(
                    { id: "HarvestingBiomassMovingToFast" },
                    { amount: intl.formatNumber(
                        estimateLiveWeight,
                        {
                          style: "decimal",
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }
                      )},
                  )}
                </Typography>
              </Grid>
              <CleaningFields isEditing={false}/>
              <Grid item xs={12}>
                <TextField
                  aria-label={intl.formatMessage({ id: "AdditionalInformation" })}
                  fullWidth={true}
                  label={intl.formatMessage({ id: "AdditionalInformation" })}
                  onChange={(event) => setDetails(event.target.value)}
                  value={details || ""}
                />
              </Grid>
            </Fragment>}
        </Grid>
      </ModalContent>
      <ModalActions>
        <Tooltip title={intl.formatMessage({id: "HotkeyEnter"})}>
          <Button
            disabled={
              amountToFastOrHarvest === 0 || Number.isNaN(amountToFastOrHarvest) || !!error
            }
            color="primary"
            onClick={() => {
            calculateHarvestingValues();
            // calculateHarvestingValues(isRetroactiveHarvest ? fastToHarvest?.biomass : avgFishWeight * amountToFastOrHarvest / 1000 || 0)
          }}>
            {intl.formatMessage({ id: "HarvestingEstimateValues" })}
          </Button>
        </Tooltip>
        <Button
          color="primary"
          disabled={
            amountToFastOrHarvest === 0 || Number.isNaN(amountToFastOrHarvest) || !!error
          }
          onClick={performAction}
        >
          {intl.formatMessage({ id: "Save" })}
        </Button>
        <Button color="primary" onClick={clearAll}>
          {intl.formatMessage({ id: "Cancel" })}
        </Button>
      </ModalActions>
    </Modal>
  );
});

export const FastAndCleanButton = observer(() => {
  const intl = useIntl();
  const { selectedBasin } = useStore('basinStore');
  const { action, setActionFastAndClean } = useStore('harvestingStore');

  return (!selectedBasin?.currentamount) ? null : (
    <Box display="flex" m={2}>
      <Button
        onClick={() => setActionFastAndClean()}
        startIcon={<ArrowForwardIcon />}
        variant="outlined"
      >
        {intl.formatMessage({ id: "FastAndHarvest" })}
      </Button>
      {action !== HarvestingAction.FASTANDHARVEST ? null : <FastAndCleanDialog />}
    </Box>
  );
});
