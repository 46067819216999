import { makeAutoObservable, runInAction } from 'mobx';
import {
  FeedingScheduleModel,
  IFeedingSchedule,
  IScheduleUpdateRequest,
} from '../models/feeding-schedule';
import basinStore from './basin-store';
import iotDeviceStore from './iot-device-store';
import {
  getActiveFeedingSchedule,
  IApiResponse,
  putActiveFeedingSchedule,
  getOperationById,
  getCompanyFeedingScheduleProfiles,
  saveCompanyFeedingScheduleProfile,
  deleteCompanyFeedingScheduleProfile,
} from 'services/api';
import { IotCommandResponse } from 'models/iot-devices';
import toastStore from './toast-store';

class FeedingScheduleStore {
  constructor() {
    makeAutoObservable(this);
  }

  schedule: FeedingScheduleModel | undefined;
  modifiedTimeStamp: number = new Date().getTime();
  private pollingErrorCount: number = 0;
  private pollingTimer: number | undefined;
  scheduleProfiles: IFeedingSchedule[] = [];

  loadSchedule = async () => {
    const { selectedBasin } = basinStore;
    const deviceInfo = iotDeviceStore.getDeviceInfo(selectedBasin?.supportunit_id);
    if (selectedBasin && selectedBasin?.supportunit_id && deviceInfo) {
      const activeSchedule = await this.loadActingFeedingSchedule(
        selectedBasin?.supportunit_id
      );
      runInAction(() => {
        this.schedule = new FeedingScheduleModel(
          activeSchedule,
          selectedBasin,
          deviceInfo
        );
      });
      await this.loadScheduleProfiles();
    }
  };

  setScheduleFromProfile = async (profileSchedule: IFeedingSchedule) => {
    const { selectedBasin } = basinStore;
    const deviceInfo = iotDeviceStore.getDeviceInfo(selectedBasin?.supportunit_id);
    if (selectedBasin && selectedBasin?.supportunit_id && deviceInfo) {
      runInAction(() => {
        this.schedule = new FeedingScheduleModel(
          profileSchedule,
          selectedBasin,
          deviceInfo
        );
      });
    }
  };

  loadScheduleProfiles = async () => {
    const { selectedBasin } = basinStore;
    if (selectedBasin) {
      const companyId = selectedBasin.site.facility.company?.id;
      if (companyId) {
        this.scheduleProfiles = await this.loadCompanyFeedingScheduleProfiles(companyId);
      }
    }
  }

  updateSchedule = async (
    scheduleJson: IScheduleUpdateRequest
  ): Promise<IApiResponse<IFeedingSchedule>> => {
    try {
      const { selectedBasin } = basinStore;
      if (!selectedBasin?.supportunit_id) throw new Error('no selected basin');
      const response = await putActiveFeedingSchedule(
        selectedBasin.supportunit_id,
        scheduleJson
      );

      // Integration doesn't currently support this, so I comment it out to not break feeding schedule update
      // What it does is make sure the schedule is updated on the device, but we can make do without it for now

      // const { data: operations } = await getOperations(
      //   selectedBasin.supportunit_id
      // );
      // if (operations.length && operations[0].description === 'Set schedule') {
      //   this.pollingErrorCount = 0;
      //   this.pollPendingOperation(operations[0]);
      // }
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error: any) {
      return {
        status: 400,
        data: null,
        errors: error,
      };
    }
  };

  pollPendingOperation = (operation: IotCommandResponse) => {
    this.pollingTimer = window.setTimeout(async () => {
      try {
        const { data } = await getOperationById(
          operation.deviceId,
          operation.id
        );

        if (data.status !== 'PENDING' && data.status !== 'EXECUTING') {
          toastStore.setToast('FeedingScheduleUpdateSuccessfully', 'success');
        } else {
          if (this.pollingErrorCount <= 5) {
            this.pollPendingOperation(operation);
          } else {
            throw new Error('Polling error count reached');
          }
        }
      } catch (error) {
        console.log('Schedule update failed', error);
        toastStore.setToast('ScheduleUpdateToDeviceFailed');
        clearTimeout(this.pollingTimer);
      }
    }, 1000);
  };

  private loadActingFeedingSchedule = async (deviceId: string) => {
    try {
      const { data } = await getActiveFeedingSchedule(deviceId);
      return data;
    } catch (error) {
      return {
        name: 'DEFAULT',
        companyId: null,
        entries: [],
      };
    }
  };

  modifyEntryTimeCallback = (
    rowId: string | undefined,
    columnIndex: number
  ) => (values: { hours: number; minutes: number; seconds: number }) => {
    if (!this.schedule) return;
    const entries = this.schedule.entries.map((entry) => {
      if (entry.rowId === rowId) {
        if (columnIndex === 0) {
          entry.hourStart = values.hours;
          entry.minuteStart = values.minutes;
          entry.secondStart = values.seconds;
        }
        if (columnIndex === 2) {
          entry.timeFeeding = values.hours * 3600 + values.minutes * 60 + values.seconds;
        }
        if (columnIndex === 3) {
          entry.timeIdle = (values.minutes * 60) + values.seconds;
        }
      }
      return entry;
    });
    runInAction(() => {
      if (this.schedule) {
        this.schedule?.updateEntries(entries);
        this.modifiedTimeStamp = new Date().getTime();
      }
    });
  };

  modifyEntryPercentCallback = (rowId: string | undefined) => (
    percent: number
  ) => {
    if (!this.schedule) return;
    const index = this.schedule.entries.findIndex((e) => e.rowId === rowId);
    if (index >= 0) {
      runInAction(() => {
        if (this.schedule) {
          const { entries } = this.schedule;
          entries[index].percentageOfDailyFeeding = percent;
          this.schedule?.updateEntries(entries);
          this.modifiedTimeStamp = new Date().getTime();
        }
      });
    }
  };

  deleteEntry = (rowId: string | undefined) => () => {
    if (rowId) {
      runInAction(() => {
        this.schedule?.deleteEntry(rowId);
        this.modifiedTimeStamp = new Date().getTime();
      });
    }
  };

  addScheduleEntry = () => {
    runInAction(() => {
      this.schedule?.addEntry();
      this.modifiedTimeStamp = new Date().getTime();
    });
  };

  private loadCompanyFeedingScheduleProfiles = async (companyId: number) => {
    try {
      const { data } = await getCompanyFeedingScheduleProfiles(companyId);
      return data;
    } catch (error) {
      return [];
    }
  };

  saveCompanyFeedingScheduleProfile = async (
    scheduleJson: IScheduleUpdateRequest
  ): Promise<IApiResponse<IFeedingSchedule>> => {
    try {
      const { selectedBasin } = basinStore;
      const companyId = selectedBasin?.site.facility.company?.id;
      if (companyId) {
        const response = await saveCompanyFeedingScheduleProfile(
          companyId, scheduleJson
        );
        return {
          status: response.status,
          data: response.data,
        };
      } else {
        return {
          status: 400,
          data: null,
          errors: new Error("company id is undefined"),
        };
      }
    } catch (error: any) {
      return {
        status: 400,
        data: null,
        errors: error,
      };
    }
  };

  deleteCompanyFeedingScheduleProfile = async (feedingScheduleId: number) => {
    try {
      return await deleteCompanyFeedingScheduleProfile(feedingScheduleId);
    } catch (error) {
      return {
        status: 400,
        data: null,
        errors: error,
      };
    }
  };


}
export default new FeedingScheduleStore();
