import { makeAutoObservable, runInAction } from 'mobx';
import { IFacility } from 'models/facility';
import {
  getFacilities,
  deleteFacility,
  createFacilityForCompany,
  updateFacility,
} from '../services/api';
import userStore from './user-store';
import companyStore from './company-store';

class FacilityStore {
  constructor() {
    makeAutoObservable(this);
  }

  facilities: IFacility[] = [];
  facilityScrollToTarget: number | undefined; // used as scrollToTarget when navigating to facilityView
  deleteList: number[] = [];

  reset = () => {
    this.facilities = [];
    this.facilityScrollToTarget = undefined; // used as scrollToTarget when navigating to facilityView
    this.deleteList = [];
  };

  async loadFacilities() {
    const response = await getFacilities();
    runInAction(() => {
      this.facilities = response.data;
    });
  }

  get pendingDeleteCount() {
    return this.deleteList.length;
  }

  updateDeleteList(companyId: number, action: 'add' | 'remove') {
    if (action === 'remove') {
      runInAction(() => {
        this.deleteList = this.deleteList.filter((id) => id !== companyId);
      });
    }
    if (action === 'add') {
      runInAction(() => {
        this.facilities = this.facilities.filter((c) => c.id !== companyId);
        this.deleteList.push(companyId);
      });
    }
  }

  clearDeleteList() {
    runInAction(() => {
      this.deleteList = [];
    });
  }

  cancelDelete() {
    runInAction(() => {
      this.deleteList = [];
      this.loadFacilities();
    });
  }

  updateFacility = async (id: number, facility: IFacility): Promise<any> => {
    try {
      const response = await updateFacility(id, facility);
      runInAction(() => {
        const index = this.facilities.findIndex((f) => f.id === id);
        this.facilities[index] = response.data;
      });
      return response?.data;
    } catch (error: any) {
      return { errors: error?.response?.data, status: error?.response?.status };
    }
  };

  createFacility = async (
    companyId: number | null,
    facility: IFacility
  ): Promise<any> => {
    try {
      const id = userStore.isRaisioAdminOrSuperuser
        ? companyId
        : companyStore?.companies?.[0]?.id;
      if (id) {
        const response = await createFacilityForCompany(id, facility);
        runInAction(() => {
          this.facilities = [...this.facilities, response.data];
        });
        return response?.data;
      }
    } catch (error: any) {
      return { errors: error?.response?.data, status: error?.response?.status };
    }
  };

  setFacilityScrollToTarget = (siteId: number | undefined) => {
    runInAction(() => {
      this.facilityScrollToTarget = siteId;
    });
  };

  /**
   * Deletes facilities one by one. Django API dos not currently support ´batch delete, but this is OK for now.
   */
  async deleteFacilities(): Promise<number[]> {
    const failedIds: number[] = [];
    const promises = this.deleteList.map(async (id) => {
      try {
        await deleteFacility(id);
        runInAction(() => {
          // clear id from delete list after successfully removal
          this.deleteList = this.deleteList.filter((dId) => dId !== id);
        });
      } catch (error) {
        failedIds.push(id);
      }
    });
    await Promise.all(promises);
    return failedIds;
  }
}

export default new FacilityStore();
