import { AxiosResponse } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { AlarmTypes } from '../models/alarm';
import { ITemperature, ISimpleSite, IOxygenValue } from '../models/site';
import {
  getSiteDetailsByFacilityId,
  setBasinTempForSite,
  createSiteForFacility,
  updateSite,
  IApiResponse,
  deleteSite,
  getDepthsBySensorType,
  setBasinOxygenValueForSite,
  getLastOxygenValueByDepthFromIotDevice,
  getLastTemperatureByDepthFromIotDevice,
  getSiteById,
  updateThresholds,
  getSitesByCompanyId,
  getSites,
  getIotDeviceActiveAlarms,
} from '../services/api';
import toastStore from './toast-store';
import {IFishBasin} from '../models/fishbasin';

class SiteStore {
  constructor() {
    makeAutoObservable(this);
  }

  simplifiedSites: ISimpleSite[] = [];
  deleteList: number[] = [];
  isLoading = false;
  pendingSiteLoads: number[] = [];
  oxygenDepths: string[] = [];
  temperatureDepths: string[] = [];

  expandedSites: Record<number, boolean> = {};
  selectedSite: ISimpleSite | null = null;

  get selectedSitesBasinsWithIot() {
    return this.selectedSite?.fishbasins?.filter(basin => !!basin.supportunit_id) || [];
  }

  getFacilitysBasinsWithIot = (facilityId: number): IFishBasin[] => {
    const facilitySites = this.simplifiedSites.filter(site => site.facility.id === facilityId);
    if(!facilitySites) return [];
    return facilitySites.flatMap(site => site.fishbasins || []).filter(basin => !!basin.supportunit_id);
  }

  setSelectedSite = (site: ISimpleSite) => {
    runInAction(() => {
      this.selectedSite = site;
    });
  };

  loadSite = async (siteId: number): Promise<IApiResponse<ISimpleSite>> => {
    try {
      const response = await getSiteById(siteId);
      runInAction(() => {
        this.selectedSite = response.data;
      });
      return {
        status: response.status,
        data: response?.data,
      };
    } catch (error: any) {
      return {
        errors: error?.response?.data,
        status: error?.response?.status,
        data: null,
      };
    }
  };

  loadSites = async (): Promise<IApiResponse<ISimpleSite[]>> => {
    try {
      const response = await getSites();
      const withAlarmCount = await this.fetchAlarmCountToSimpleSites(response);

      runInAction(() => {
        this.simplifiedSites = withAlarmCount
      });
      return {
        status: response.status,
        data: response?.data,
      };
    } catch (error: any) {
      return {
        errors: error?.response?.data,
        status: error?.response?.status,
        data: null,
      };
    }
  };

  loadSitesByCompanyId = async (companyId: number) => {
    const response = await getSitesByCompanyId(companyId);
    runInAction(() => {
      this.simplifiedSites = response.data;
    });
  }

  loadSiteDetailsByFacilityId = async (facilityId: number) => {
    runInAction(() => {
      this.pendingSiteLoads.push(facilityId);
    });
    const response = await getSiteDetailsByFacilityId(facilityId);
    runInAction(() => {
      this.pendingSiteLoads = this.pendingSiteLoads.filter(
        (id) => id !== facilityId
      );
    });
    return this.fetchAlarmCountToSimpleSites(response)
  };

  fetchAlarmCountToSimpleSites = async (response: AxiosResponse<ISimpleSite[]>) => {
    const supportunitIds = response.data
      .filter(site => site.deleted === false && site.fishbasins !== undefined)
      .flatMap(site => site.fishbasins)
      .filter((basin): basin is IFishBasin => basin !== undefined && basin.deleted === false)
      .map(basin => basin.supportunit_id)
      .filter((id): id is string => id !== undefined)
      .filter((sui) => sui !== null);

    let activeAlarms: any[] = [];
    try {
      const { data } = await getIotDeviceActiveAlarms(supportunitIds);
      activeAlarms = data;
    } catch (error) {
      console.error('Failed to fetch active alarms:', error);
    }

    const alarmCounts = activeAlarms.reduce((acc: { [key: string]: number }, alarm: any) => {
      const { source } = alarm;
      if (source) {
        acc[source] = (acc[source] || 0) + 1;
      }
      return acc;
    }, {});

    return response.data.map(site => {
      if (site.deleted === false && site.fishbasins !== undefined) {
        const updatedFishbasins = site.fishbasins.map(basin => {
          if (basin.deleted === false) {
            const supportunitId = basin.supportunit_id;
            const count = supportunitId ? alarmCounts[supportunitId] || 0 : 0;
            return {
              ...basin,
              activeAlarms: count > 0,
              activeAlarmsCount: count,
            };
          }
          return basin;
        });
        return {
          ...site,
          fishbasins: updatedFishbasins,
        };
      }
      return site;
    });
  }

  updateTemperature = async (site: ISimpleSite, temperature: number) => {
    let resp: AxiosResponse<ITemperature>;
    try {
      resp = await setBasinTempForSite(site.id, temperature);
      runInAction(() => {
        if (this.selectedSite && site.id === this.selectedSite.id) {
          this.selectedSite.last_temperature = { ...resp.data };
        }
      });
      return { status: resp.status, site };
    } catch (error: any) {
      return { status: error.response.status, site: null };
    }
  };

  updateOxygen = async (site: ISimpleSite, oxygenValue: number) => {
    let resp: AxiosResponse<IOxygenValue>;
    try {
      resp = await setBasinOxygenValueForSite(site.id, oxygenValue);
      runInAction(() => {
        if (this.selectedSite && site.id === this.selectedSite.id) {
          this.selectedSite.last_oxygen_value = { ...resp.data };
        }
      });
      return { status: resp.status, site };
    } catch (error: any) {
      return { status: error.response.status, site: null };
    }
  };

  updateSite = async (
    id: number,
    name: string
  ): Promise<IApiResponse<ISimpleSite>> => {
    try {
      const response = await updateSite(id, { name });
      return {
        status: response.status,
        data: response?.data,
      };
    } catch (error: any) {
      return {
        errors: error?.response?.data,
        status: error?.response?.status,
        data: null,
      };
    }
  };

  createSite = async (
    facilityId: number,
    name: string,
    temperature?: number
  ): Promise<IApiResponse<ISimpleSite>> => {
    try {
      const response = await createSiteForFacility(facilityId, {
        name,
        temperature,
      });
      return {
        status: response?.status,
        data: response?.data,
      };
    } catch (error: any) {
      return {
        errors: error?.response?.data,
        status: error?.response?.status,
        data: null,
      };
    }
  };

  updateDeleteList(companyId: number, action: 'add' | 'remove') {
    if (action === 'remove') {
      runInAction(() => {
        this.deleteList = this.deleteList.filter((id) => id !== companyId);
      });
    }
    if (action === 'add') {
      runInAction(() => {
        this.deleteList.push(companyId);
      });
    }
  }

  clearDeleteList() {
    runInAction(() => {
      this.deleteList = [];
    });
  }

  cancelDelete() {
    runInAction(() => {
      this.deleteList = [];
    });
  }

  /**
   * Deletes sites one by one. Django API dos not currently support ´batch delete, but this is OK for now.
   */
  async deleteSites(): Promise<number[]> {
    const failedIds: number[] = [];
    const promises = this.deleteList.map(async (id) => {
      try {
        await deleteSite(id);
        runInAction(() => {
          // clear id from delete list after successfully removal
          this.deleteList = this.deleteList.filter((dId) => dId !== id);
        });
      } catch (error) {
        failedIds.push(id);
      }
    });
    await Promise.all(promises);
    return failedIds;
  }

  setAutoTemperatureUpdate = async (
    id: number,
    deviceId: string | undefined,
    isAutomatic: boolean,
    depth: string
  ) => {
    try {
      const promises: Promise<AxiosResponse<any>>[] = [updateSite(id, {
        automatic_temperature_update: isAutomatic,
        preferred_depth: depth,
      })];

      if (deviceId) {
        promises.push(updateThresholds([{
          deviceId: deviceId,
          event: AlarmTypes.TEMPERATURE,
          depth: depth,
        }]));
      }

      await Promise.all(promises);
    } catch (error) {
      toastStore.setToast('FailedToSetAutomaticTemperatureUpdate');
    }
  };

  setAutoOxygenUpdate = async (
    id: number,
    deviceId: string | undefined,
    isAutomatic: boolean,
    depth: string
  ) => {
    try {
      const promises: Promise<AxiosResponse<any>>[] = [updateSite(id, {
        automatic_oxygen_update: isAutomatic,
        preferred_depth_oxygen: depth,
      })];

      if (deviceId) {
        promises.push(updateThresholds([{
          deviceId: deviceId,
          event: AlarmTypes.OXYGEN,
          depth: depth,
        }]));
      }

      await Promise.all(promises);
    } catch (error) {
      toastStore.setToast('FailedToSetAutomaticOxygenUpdate');
    }
  };

  setExpandedSites = (expandedSites: Record<number, boolean>) => {
    runInAction(() => {
      this.expandedSites = expandedSites;
    });
  };

  setExpandedSite = (id: number, isExpanded: boolean) => {
    runInAction(() => {
      this.expandedSites[id] = isExpanded;
    });
  };

  loadSensorDepthValues = async (
    deviceId: string,
    type: 'temperature' | 'oxygen'
  ) => {
    try {
      const { data: depths } = await getDepthsBySensorType(deviceId, type);
      runInAction(() => {
        if (type === 'oxygen') this.oxygenDepths = depths;
        if (type === 'temperature') this.temperatureDepths = depths;
      });
      return depths;
    } catch (error) {
      runInAction(() => {
        if (type === 'oxygen') this.oxygenDepths = [];
        if (type === 'temperature') this.temperatureDepths = [];
      });
      console.log(
        `error loading depths for type: ${type}, and deviceID: ${deviceId}`
      );
      return [];
    }
  };

  getOxygenValueForDepth = async (deviceId: string, depth: string) => {
    try {
      const {
        data: oxygen
      } = await getLastOxygenValueByDepthFromIotDevice(deviceId, depth)
      return Number(oxygen.value.toFixed(2));
    } catch (error) {
      return null;
    }
  };

  getTemperatureForDepth = async (deviceId: string, depth: string) => {
    try {
      const {
        data: temperature,
      } = await getLastTemperatureByDepthFromIotDevice(deviceId, depth);
      return Number(temperature.value.toFixed(2));
    } catch (error) {
      return null;
    }
  };
}

export default new SiteStore();
