import {makeAutoObservable, runInAction} from 'mobx';
import {getNotificationSettings, IApiResponse, patchNotificationSettings,} from 'services/api';
import {
  AlarmTypes,
  IDeviceSettings,
  INotificationSettings,
  IReceiverSettings,
  IThresholdsSettings,
  lowerThresholdTypes,
  upperThresholdTypes,
} from 'models/alarm';
import {ISimpleSite} from 'models/site';
import {alarmTypes} from "../components/alarm-settings/alarm-types";

class AlarmSettingsStore {
  constructor() {
    makeAutoObservable(this);
  }

  settings: INotificationSettings = {
    thresholds: [],
    devices: [],
    receivers: {phoneNumbers: [], emailAddresses: []} as IReceiverSettings,
    conflict: false
  } as INotificationSettings;

  batterySaveEnabled: boolean = false;

  get tooManyPhoneNumbers() {
    return this.settings.receivers.phoneNumbers.length > 3;
  }

  get canSaveThresholds() {
    return !Object.values(this.settings.thresholds).find((t) =>
      t && (isNaN(t.upper === null ? 0 : t.upper) ||
            isNaN(t.lower === null ? 0 : t.lower))
    );
  }

  get canSaveBatterySave() {
    if(!this.batterySaveEnabled) return true;
    const threshold = this.getThreshold(AlarmTypes.BATTERY_VOLTAGE);
    if(!threshold) return true
    return (threshold.upper || 0) > (threshold.lower || 0);
  }

  loadNotificationSettings = async (site: ISimpleSite): Promise<IApiResponse<any>> => {

    const devices = site.fishbasins?.filter(
      (basin) => !!basin.supportunit_id
    )?.map((basin) => basin.supportunit_id || "") || [];
    if (devices.indexOf(site.mainunit_id || "") < 0) {
      devices.push(site.mainunit_id || "");
    }

    try {
      if (devices.length === 0) throw new Error('No IoT device ids available');

      const response = await getNotificationSettings(devices);
      runInAction(() => {
        this.settings = response.data;
      });

      return {
        status: response.status,
        data: response.data,
        errors: undefined,
      };
    } catch (error: any) {
      runInAction(() => {
        this.settings.thresholds = alarmTypes.map(type => {
          return {
            event: type.type, preferredDepth: null, upper: null, lower: null, smsEnabled: false, emailEnabled: false
          } as IThresholdsSettings
        })
        this.settings.devices = devices.map(device => {
          return { deviceId: device, checked: false } as IDeviceSettings
        })
      })

      return {
        status: error.status,
        data: null,
        errors: error,
      };
    }
  };

  getDeviceSetting = (deviceId: string): IDeviceSettings | undefined =>
    this.settings.devices.find((d) => d.deviceId === deviceId)

  isDeviceActive = (deviceId: string): boolean => {
    const deviceSetting = this.getDeviceSetting(deviceId);
    if(deviceSetting) return deviceSetting.checked;
    else return false;
  }

  addActiveDevice = (deviceId: string) => {
    runInAction(() => {
      const existingDevice = this.getDeviceSetting(deviceId);
      if(existingDevice) existingDevice.checked = true
    });
  };

  removeActiveDevice = (deviceId: string) => {
    runInAction(() => {
      const existingDevice = this.getDeviceSetting(deviceId);
      if(existingDevice) existingDevice.checked = false
    });
  };

  setAllDevicesActive = () => {
    runInAction(() => {
      this.settings.devices.forEach(d => d.checked = true)
    });
  };

  setAllDevicesInactive = () => {
    runInAction(() => {
      this.settings.devices.forEach(d => d.checked = false)
    });
  };

  getThreshold = (type: AlarmTypes) => this.settings.thresholds.find((t => t.event === type));

  isSubscribedToEmail = (type: AlarmTypes) => {
    const event = this.getThreshold(type)
    return event ? event.emailEnabled : false;
  }

  isSubscribedToSms = (type: AlarmTypes) => {
    const event = this.getThreshold(type)
    return event ? event.smsEnabled : false;
  }

  subscribeToEmail = (type: AlarmTypes) => {
    runInAction(() => {
      const event = this.getThreshold(type);
      if(event) event.emailEnabled = true;
    });
  };

  unsubscribeFromEmail = (type: AlarmTypes) => {
    runInAction(() => {
      const event = this.getThreshold(type);
      if(event) event.emailEnabled = false;
    });
  };

  subscribeToSms = (type: AlarmTypes) => {
    runInAction(() => {
      const event = this.getThreshold(type);
      if(event) event.smsEnabled = true;
    });
  };

  unsubscribeFromSms = (type: AlarmTypes) => {
    runInAction(() => {
      const event = this.getThreshold(type);
      if(event) event.smsEnabled = false;
    });
  };

  updatePhoneNumbers = (phoneNumbers: string[]) => {
    runInAction(() => {
      this.settings.receivers.phoneNumbers = phoneNumbers;
    });
  };

  updateEmails = (emails: string[]) => {
    runInAction(() => {
      this.settings.receivers.emailAddresses = emails;
    });
  };

  getUpperThreshold = (type: AlarmTypes) => {
    const threshold = this.getThreshold(type);
    return !threshold ? null : threshold.upper;
  }

  getLowerThreshold = (type: AlarmTypes) => {
    const threshold = this.getThreshold(type);
    return !threshold ? null : threshold.lower;
  };

  hasUpperThreshold = (type: AlarmTypes) =>
    !!upperThresholdTypes.find((t) => t === type);

  hasLowerThreshold = (type: AlarmTypes) =>
    !!lowerThresholdTypes.find((t) => t === type);

  setUpperThreshold = (type: AlarmTypes, value: number | null) => {
    runInAction(() => {
      const threshold = this.getThreshold(type);
      if(threshold) {
        threshold.upper = value;
      }
    });
  };

  setLowerThreshold = (type: AlarmTypes, value: number | null) => {
    runInAction(() => {
      const threshold = this.getThreshold(type);
      if(threshold) {
        threshold.lower = value;
      }
    });
  };

  getBatterySave = () => {
    return this.batterySaveEnabled;
  }

  setBatterySave = (enabled: boolean) => {
    runInAction(() => {
      this.batterySaveEnabled = enabled;
      if(!enabled) this.setUpperThreshold(AlarmTypes.BATTERY_VOLTAGE, 0.0);
    });
  };

  saveNotificationSettings = async (site: ISimpleSite): Promise<IApiResponse<any>> => {
    const oxygenThreshold = this.getThreshold(AlarmTypes.OXYGEN);
    if(oxygenThreshold) oxygenThreshold.preferredDepth = (site?.preferred_depth_oxygen || null)
    const temperatureThreshold = this.getThreshold(AlarmTypes.TEMPERATURE)
    if(temperatureThreshold) temperatureThreshold.preferredDepth = (site?.preferred_depth || null)

    try {
      return await patchNotificationSettings(this.settings);
    } catch (error: any) {
      return {
        status: error.status,
        data: null,
        errors: error,
      };
    }
  }
}

export default new AlarmSettingsStore();
